import { Component, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';   // npm install --save @types/crypto-js
import { AliceAutoLoginService } from '../services/alice-auto-login.service';
import { ActivatedRoute } from '@angular/router';
import { AliceAutoLogin } from '../models/alice-auto-login';

@Component({
  selector: 'app-alice-ant-auto-login',
  templateUrl: './alice-ant-auto-login.component.html',
  styleUrls: ['./alice-ant-auto-login.component.css']
})
export class AliceAntAutoLoginComponent implements OnInit {

  tokenFromUI: string = '0123456789123456';
  encrypted: any = '';
  decrypted: string;

  request: string;
  responce: string;

  autoLoginParam : any;
  algoAdminId : number;
  aliceAutoLoginList : AliceAutoLogin[];
  aliceUserData : AliceAutoLogin[];
  loginMessage: string; 
  downloadStock : string;
  splitStock : string;

  constructor(private aliceAutoLoginService : AliceAutoLoginService, private route: ActivatedRoute) {     
  }

  ngOnInit(): void {
  //   this.autoLoginParam =  this.route.queryParams.subscribe(loginParams => { 
              
  //     console.log("AutoLogin Inputs: " + loginParams);
  //     this.algoAdminId = loginParams.algoAdminId;   
      
  //     if(loginParams.algoAdminId === undefined)
  //     this.algoAdminId = 0;      
  // });

  this.route.paramMap.subscribe(params => {               
    console.log("AutoLogin Inputs: " + params);
    this.algoAdminId = Number(params.get('algoAdminId'));  
  });
  
  //this.algoAdminId = 1; //this.algoUserProfileService.GetUserDetailsFromSession();
    this.getUserLoggedInStatus();

    this.getAliceBlue_DownloadStockInstrumentList();
    this.getAliceBlue_SplitStockInstrumentTokenDetails();
  }

  encryptUsingAES256(password : string, encKey : string) {    
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

    // let encrypted = CryptoJS.AES.encrypt(
    //   JSON.stringify(this.request), _key, {
    //     keySize: 16,
    //     iv: _iv,
    //     mode: CryptoJS.mode.ECB,
    //     padding: CryptoJS.pad.Pkcs7
    //   });


    //let encrypted = CryptoJS.AES.encrypt('Mugesh@5393', 'E27BXI43MWKL');

    let encrypted = CryptoJS.AES.encrypt(password, encKey);
    this.encrypted = encrypted.toString();    
    return this.encrypted;
  }

  decryptUsingAES256() {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

    this.decrypted = CryptoJS.AES.decrypt(this.encrypted, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
  }

  

  getUserLoggedInStatus() {
    this.aliceAutoLoginService.AliceAntWebEncryptionKeyGenerate(this.algoAdminId).subscribe(data => {
      if (data != null && data.length > 0) {
        console.log("Alice AutoLogin Encryp Key Generate successfully");
        this.aliceAutoLoginList = data;

        this.aliceUserData = [];
        this.aliceAutoLoginList.forEach(login => {

          var userData = this.encryptUsingAES256(login.accountLoginPassword, login.encryptionKey);

          this.aliceUserData.push({ 'userId': login.userId, 'adminId': login.adminId, 'accountLoginId': login.accountLoginId, 'accountLoginPassword': login.accountLoginPassword, 'tradeTwoFaAnswer1': login.tradeTwoFaAnswer1, 'encryptionKey': login.encryptionKey, 'userData': userData, 'otpSecretKey' : login.otpSecretKey, 'aliceWebLoginStatus': login.aliceWebLoginStatus });
        });

        if (this.aliceUserData != null && this.aliceUserData.length > 0) {
          this.aliceAutoLoginService.AliceAntWebAutoLogin(this.aliceUserData).subscribe(aliceLogin => {

            if (aliceLogin != null && aliceLogin.status === 'success') {

              this.loginMessage = 'Login Successfully';
            }
          });
        }

      }
    });
    window.localStorage.removeItem("WebSocket_TokenSession");
    window.localStorage.removeItem("WebSocket_UserId");
  }

  getAliceBlue_DownloadStockInstrumentList(){            
    this.aliceAutoLoginService.getAliceBlue_DownloadStockInstrumentList().subscribe(data => {        
      
      if(data!=null && data.message =="success"){                                      
        console.log("getTOTP_DetailsByUserId " + data.status);
        this.downloadStock = "Download Stock InstrumentList Success";  
      }
      else 
      this.downloadStock = "Download Stock InstrumentList Error"; 
    });
  }

  getAliceBlue_SplitStockInstrumentTokenDetails(){           
    this.aliceAutoLoginService.getAliceBlue_SplitStockInstrumentTokenDetails().subscribe(data => {         
      if(data!=null && data.message =="success"){                              
        console.log("getTOTP_DetailsByUserId " + data.status);
        this.splitStock = "Split Stock Instrument Success";
      }
      else 
      this.splitStock = "Split Stock Instrument Error";

    });
  }
}
