import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate,CanActivateChild  {

  constructor(private router: Router) {}

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const url: string = "";//state.url;
    
    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  checkLogin(url: string) {    
    if (this.isLoggedIn()) {
      return true;
    }
    else {
      this.router.navigate(['/login']);
      return false;
    }    
    //this.router.navigate(['/login'], {queryParams: { returnUrl: url }} );
  }

  isLoggedIn() {
  
    if(window.localStorage.getItem("ALGO_SITE_KEY")!=null){
      //1this.algoAdminId =Number(window.localStorage.getItem("ALGO_SITE_KEY"));    
      return true;  
    }
    else 
    return false;

    // if (localStorage.getItem('currentUser')) {
    //   return true;
    // }
    // return false;
  }
  
}
