<!-- <p>algo-login works!</p> -->

<mat-sidenav-container class="mat-drawer-container mat-sidenav-container">
</mat-sidenav-container>
    <!-- <div class="mat-drawer-backdrop ng-star-inserted"></div> -->
    
    <!-- <mat-sidenav-content class="mat-drawer-content mat-sidenav-content ng-star-inserted">
     
     
      
    </mat-sidenav-content> -->

  <div class="">
    <div class="login-register" style="background-image: url(assets/images/background/login-register.jpg);">
      <div class="login-register-box">
        <mat-card class="mat-card mat-focus-indicator" *ngIf="isShowLogin">
          <mat-card-content class="mat-card-content">           
              <div class="text-center">
                <img alt="homepage" src="./assets/images/logo-icon.png">
                <h4 class="m-t-0">Login to App</h4>
              </div>

              <div fxlayout="row wrap" style="flex-flow: row wrap; box-sizing: border-box; display: flex;">
                
                <!-- <form class="login-form"> -->
                <div fxflex.gt-sm="100" fxflex.gt-xs="100" fxflex="100" style="flex: 1 1 100%; box-sizing: border-box; max-width: 100%;">
                  

                  <mat-form-field appearance="fill">
                    <mat-label>Username</mat-label>
                    <input matInput #input placeholder="Ex. UserId" [(ngModel)]="algoUserId" required>
                    <mat-hint align="end">000001</mat-hint>
                  </mat-form-field>

                  <small>Username is required.</small>
                  
                </div>



                <div fxflex.gt-sm="100" fxflex.gt-xs="100" fxflex="100" style="flex: 1 1 100%; box-sizing: border-box; max-width: 100%;">
                    <mat-form-field appearance="fill">
                        <mat-label>Password</mat-label>
                        <input [type]="showPassword ? 'text' : 'password'" matInput #input placeholder="Ex. xyz001" [(ngModel)]="algoPassword" required>
                        <mat-icon matSuffix (click)="togglePasswordVisibility()">{{showPassword?'visibility_off':'visibility'}}</mat-icon>
                        <mat-hint align="end">xyz001</mat-hint>
                      </mat-form-field>

                   
                  <small>Password is required.</small>
                  
                </div>
                <div  fxflex.gt-sm="50" fxflex.gt-xs="50" style="flex: 1 1 50%; box-sizing: border-box; max-width: 50%;">
                  <mat-checkbox color="warn" class="mat-checkbox mat-warn" > Remember me</mat-checkbox>                        
                 
                </div>

                 <div fxflex.gt-sm="50" fxflex.gt-xs="50" class="text-right" style="flex: 1 1 50%; box-sizing: border-box; max-width: 50%;">
                  <a class="link" href="#">Forgot password?</a>
                </div>
                <div fxflex.gt-sm="100" fxflex.gt-xs="100" fxflex="100" style="flex: 1 1 100%; box-sizing: border-box; max-width: 100%;">
                
                  <button mat-raised-button color="primary" (click)="algologin()" >Login</button>
                </div>
                <div fxflex.gt-sm="100" fxflex.gt-xs="100" fxflex="100" class="text-center" style="flex: 1 1 100%; box-sizing: border-box; max-width: 100%;">
                  <span >Don't have an account? <a class="text-info link" href="#" (click)="showRegisterPage()">Register</a>
                  </span>
                </div> 
              <!-- </form> -->
              </div>              
           
          </mat-card-content>
        </mat-card>


        <mat-card class="mat-card mat-focus-indicator" *ngIf="isRegistration">
          <mat-card-content class="mat-card-content">           
              <div class="text-center">
                <img alt="homepage" src="./assets/images/logo-icon.png">
                <h4 class="m-t-0">Register to App</h4>
              </div>

              <div fxlayout="row wrap" style="flex-flow: row wrap; box-sizing: border-box; display: flex;">
                
                
                <div fxflex.gt-sm="100" fxflex.gt-xs="100" fxflex="100" style="flex: 1 1 100%; box-sizing: border-box; max-width: 100%;">
                  

                  <mat-form-field appearance="fill">
                    <mat-label>Username</mat-label>
                    <input matInput #input placeholder="Ex. UserId" [(ngModel)]="algoUserName" required>
                    <mat-hint align="end">000001</mat-hint>
                  </mat-form-field>
                  <small>Username is required.</small>                  
                </div>



                <div fxflex.gt-sm="100" fxflex.gt-xs="100" fxflex="100" style="flex: 1 1 100%; box-sizing: border-box; max-width: 100%;">
                    <mat-form-field appearance="fill">
                        <mat-label>Password</mat-label>
                        <input [type]="showPassword ? 'text' : 'password'" matInput #input placeholder="Ex. xyz001" [(ngModel)]="algoPassword" required>
                        <mat-icon matSuffix (click)="togglePasswordVisibility()">{{showPassword?'visibility_off':'visibility'}}</mat-icon>
                        <mat-hint align="end">xyz001</mat-hint>
                      </mat-form-field>                   
                  <small>Password is required.</small>                  
                </div>                

              <div fxflex.gt-sm="100" fxflex.gt-xs="100" fxflex="100" style="flex: 1 1 100%; box-sizing: border-box; max-width: 100%;">
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input type="email" matInput #input placeholder="Ex. mail@abc.com" [(ngModel)]="algoEmail" required>
                    <mat-hint align="end">mail@abc.com</mat-hint>
                  </mat-form-field>               
              <small>Email is required.</small>              
            </div>

                <div  fxflex.gt-sm="50" fxflex.gt-xs="50" style="flex: 1 1 50%; box-sizing: border-box; max-width: 50%;">                                      
                  <mat-slide-toggle [(ngModel)]="isSubBroker"> Is Sub Broker</mat-slide-toggle>
                </div>

                 
                <div fxflex.gt-sm="100" fxflex.gt-xs="100" fxflex="100" style="flex: 1 1 100%; box-sizing: border-box; max-width: 100%;">
                
                  <button mat-raised-button color="primary" (click)="algoSiteRegister()" >Register</button>
                </div>
                <div fxflex.gt-sm="100" fxflex.gt-xs="100" fxflex="100" class="text-center" style="flex: 1 1 100%; box-sizing: border-box; max-width: 100%;">
                  <span> {{ registerMessage }} &nbsp;&nbsp; <a class="text-info link" href="#" (click)="showLoginPage()"> Click here for Login</a>
                  </span>
                </div> 
               
                
              </div>              
           
          </mat-card-content>
        </mat-card>
      </div>
    </div>
</div>
<mat-sidenav-container class="mat-drawer-container mat-sidenav-container">
</mat-sidenav-container>










