import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
//import {AppSettingsConfig } from '../../app/app.settings.config';

@Injectable({
  providedIn: 'root'
})

export class RepositoryService {
  public jsondata: any;
  public apiUrl:any;
  
 //tpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : '*',   'X-Content-Type-Options': 'nosniff', 'X-XSS-Protection': '1; mode=block'}); 
 tpHeaders = new HttpHeaders({ 'Content-Type': 'application/json',   'X-Content-Type-Options': 'nosniff', 'X-XSS-Protection': '1; mode=block'}); 

 keyCloakAccessToken = sessionStorage.getItem("access_token");

  constructor(private httpClient:HttpClient) {     
    // // Keycloak changes start
    // this.jsondata = this.SettingService.getSetting();    

    // if(this.jsondata.keycloakEnabled=="true" && this.keyCloakAccessToken!=undefined && this.keyCloakAccessToken!=null){

    //   this.tpHeaders = this.tpHeaders.set('Authorization', 'Bearer ' + this.keyCloakAccessToken);

    // }
    // // Keycloak changes end
  }

  public Post(url: string, parameter: any = null) 
  {
    // this.apiUrl = this.jsondata.ServiceURL + url; 
    this.apiUrl = url; 
     return this.httpClient.post<any>(this.apiUrl,  
       parameter, 
       { 
         headers: this.tpHeaders, 
         withCredentials: false 
       }); 
   }

   public Put(url: string, parameter: any = null) 
  {
    // this.apiUrl = this.jsondata.ServiceURL + url; 
    this.apiUrl = url; 
     return this.httpClient.put<any>(this.apiUrl,  
       parameter, 
       { 
         headers: this.tpHeaders, 
         withCredentials: false 
       }); 
   }
 
   public Get(url: string, parameter: any = null) 
   {        
    // this.apiUrl = this.jsondata.ServiceURL + url; 
    this.apiUrl = url; 
     return this.httpClient.get<any>(this.apiUrl,  
       {  
         headers: this.tpHeaders,  
         params:parameter,  
         withCredentials: false  
       });  
    // var aa= this.httpClient.get<any>(this.apiUrl);      
    // return aa;
   }

   public GetPipe(url: string, parameter: any = null) 
   { 
       // this.apiUrl = this.jsondata.ServiceURL + url; 
       this.apiUrl = url;     

       return this.httpClient.get<any>(this.apiUrl,{  
        headers: this.tpHeaders,  
        params:parameter,  
        withCredentials: false  
      }).pipe(map(result=>      {        
        console.log("get method" + result);
        return result;          
          }));          
           catchError(this.handleError<any>('get : error')); 
   }

   public Request(url: string, parameter: any = null) {
    // this.apiUrl = this.jsondata.ServiceURL  + url; 
    this.apiUrl = url; 
     return this.httpClient.request(new HttpRequest('POST',this.apiUrl, 
       parameter, 
       { 
         withCredentials: false, 
         reportProgress: true, 
       })); 
   }

   public GetFile(url: string, projectDetailId: any = null) {  
    //this.apiUrl = this.jsondata.ServiceURL +url+ projectDetailId;  
    this.apiUrl = url+ projectDetailId;    
    return this.httpClient.get(this.apiUrl, { 
        responseType: 'blob', 
        headers: this.tpHeaders, 
        withCredentials: false 
      })
    .pipe(map(data => {
      return data;
    }));
  }

  private handleError<T>(operation='',result?:T){
return (error:any):Observable<T>=>{
  console.error(error);
  console.log(`${operation} failed: ${error.message}`);
  return of(result as T);
};
  }
}
