
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy,HashLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';

import {DesignModule} from './design.module';
import { AlgoLoginComponent } from './algo-login/algo-login.component';
import { AliceAntAutoLoginComponent } from './alice-ant-auto-login/alice-ant-auto-login.component';
//import { AlgoWebSocketComponent } from './algo-web-socket/algo-web-socket.component';

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    AlgoLoginComponent,
    AliceAntAutoLoginComponent,
    //AlgoWebSocketComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    DesignModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(AppRoutes)  //, { useHash: false }
  ],
  providers: [
    {
      provide: LocationStrategy,
      //useClass: PathLocationStrategy
      useClass : HashLocationStrategy      
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
