import { Component, OnInit,AfterViewInit } from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { Router } from '@angular/router';
import { AlgoUserProfileService } from  "../services/algo-user-profile.service";
import { NotificationService } from "../services/notification.service";

@Component({
  selector: 'app-algo-login',
  templateUrl: './algo-login.component.html',
  styleUrls: ['./algo-login.component.css']
})

//const ALGO_SITE_KEY = 'auth-token';

export class AlgoLoginComponent implements OnInit {
  algoUserId : string;
  algoUserName : string;
  algoPassword : string ="";
  algoEmail : string;
  isSubBroker : boolean =false;

  isRegistration: boolean = false;
  isShowLogin : boolean = true;
  registerMessage : string;
  public showPassword: boolean = false;

  constructor(private router: Router,private algoUserProfileService : AlgoUserProfileService,private notificationService: NotificationService) { }

  ngOnInit(): void {
  }
public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  algologin(){    
    console.log("algologin");
    //localStorage.setItem('currentUser', JSON.stringify(user));
//localStorage.getItem('currentUser')
//localStorage.removeItem('currentUser');

this.algoUserProfileService.AlgoSiteUserLoginCheck(this.algoUserId,this.algoPassword).subscribe(data => {
  if(data.status=="success"){   

    // window.sessionStorage.removeItem("ALGO_SITE_KEY");
    // window.sessionStorage.setItem("ALGO_SITE_KEY", this.algoUserId.toString());

    window.localStorage.removeItem("ALGO_SITE_KEY");
    window.localStorage.setItem("ALGO_SITE_KEY", this.algoUserId.toString());

    console.log(this.algoUserId);
    console.log("Login success ");
    this.router.navigate(['/algo-order']);
    this.notificationService.success("Login successfully");
  }
  else 
    this.notificationService.error("Login failed!");
});

}

showRegisterPage(){ 
this.isRegistration = true;
this.isShowLogin = false;
}

showLoginPage(){ 
  this.isRegistration = false;
  this.isShowLogin = true;
}

algoSiteRegister(){ 
  console.log("algoSiteRegister");  

this.algoUserProfileService.AlgoSiteRegister(this.algoUserName,this.algoPassword,this.algoEmail,this.isSubBroker).subscribe(data => {
if(data.status=="success"){           

  this.registerMessage = data.message;
  
  console.log("Register success ");  
}
else { 
  this.registerMessage = data.message;
}
});
}

}