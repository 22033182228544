import { NgModule } from '@angular/core';

import {MatButtonModule} from '@angular/material/button';

import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';

import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';

import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';

import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MatIconModule } from '@angular/material/icon';

import { MatSnackBarModule } from '@angular/material/snack-bar';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

//import { MatSelectFilterModule } from 'mat-select-filter';
//import {MatTableDataSource} from '@angular/material/table';

@NgModule({
  // declarations: [],
  // imports: [
  //   CommonModule
  // ],
  exports : [MatButtonModule,MatTableModule,MatTabsModule,MatCardModule,MatFormFieldModule,
    MatInputModule,MatCheckboxModule,MatChipsModule
    ,MatPaginatorModule,MatSortModule,MatSelectModule,MatSlideToggleModule,MatIconModule,
    MatSnackBarModule,
    NgxMatSelectSearchModule
  ]
})
export class DesignModule { }
