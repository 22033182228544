import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {RepositoryService} from './repository-services/repository.service';
import {environment} from '../../environments/environment'; 

@Injectable({
  providedIn: 'root'
})
export class AliceAutoLoginService {

  private apiURL = environment.baseAlgoApiUrl;  
  
  constructor(private repositoryService :  RepositoryService) { }
  
  public AliceAntWebEncryptionKeyGenerate(algoAdminId:number) : Observable<any>{
    return this.repositoryService.Get(this.apiURL + `/AliceWebAutoLogin/AliceAntWebEncryptionKeyGenerate?algoAdminId=${algoAdminId}`);
  }

  public AliceAntWebAutoLogin(data : any) : Observable<any>
  {
    return this.repositoryService.Post(this.apiURL + `/AliceWebAutoLogin/AliceAntWebAutoLogin`,data);
  }
  public getAliceBlue_DownloadStockInstrumentList() : Observable<any>{
    return this.repositoryService.Get(this.apiURL + `/AlgoLogin/AliceBlue_DownloadStockInstrumentList`,{ responseType: 'text'});
  }
  public getAliceBlue_SplitStockInstrumentTokenDetails() : Observable<any>{
    //return this.repositoryService.Get(this.apiURL + `/AlgoLogin/AliceBlue_SplitStockInstrumentTokenDetails`,{ responseType: 'text'});
    return this.repositoryService.Get(this.apiURL + `/AlgoLogin/AliceBlue_SplitStockInstrumentTokenDetails`);
  }

  public AliceWebSocketConnectionCheck(algoAdminId:number) : Observable<any>
  {
    return this.repositoryService.Get(this.apiURL + `/AliceWebAutoLogin/GetWebSocketConnectionCheck?algoAdminId=${algoAdminId}`);
  }
}
