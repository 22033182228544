<p>alice-ant-auto-login works!</p>
<span> Login : {{ loginMessage }}</span>

<br>

<p>Download Stock InstrumentList</p>
<span> Login : {{ downloadStock }}</span>

<br>
<p>Split Stock Instrument</p>
<span> Login : {{ splitStock }}</span>