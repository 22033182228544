import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AlgoOrderComponent } from './algo-order/algo-order.component';
import {AlgoLoginComponent} from './algo-login/algo-login.component';
import { AliceAntAutoLoginComponent } from './alice-ant-auto-login/alice-ant-auto-login.component';

import { AuthGuard } from './auth/auth.guard';

export const AppRoutes: Routes = [
  {path: 'login',       component: AlgoLoginComponent },
  {path: 'alice-auto-login',       component: AliceAntAutoLoginComponent },
  {
    path: '',  component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },      
      {
        path: '',
        loadChildren:
          () => import('./material-component/material.module').then(m => m.MaterialComponentsModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'algo-order',
        loadChildren: () => import('./algo-order/order.module').then(m => m.OrderModule)
      },
      {
        path: 'user-algo-setup',
        loadChildren: () => import('./algo-setting/algo-setting.module').then(m => m.AlgoSettingModule)
      },
      {
        path: 'admin-algo-setting',
        loadChildren: () => import('./admin-algo-setting/admin-algo-setting.module').then(m => m.AdminAlgoSettingModule)
      },
      {
        path: 'algo-manual-entry',
        loadChildren: () => import('./algo-manual-entry/algo-manual-entry.module').then(m => m.AlgoManualEntryModule)
      },
      {
        path: 'algo-subscription-plan',
        loadChildren: () => import('./algo-subscription/algo-subscription.module').then(m => m.AlgoSubscriptionModule)
      },
      {
        path: 'algo-my-profile',
        loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfileModule)
      },
      {
        path: 'algo-register',
        loadChildren: () => import('./algo-register/algo-register.module').then(m => m.AlgoRegisterModule)
      },
      {
        path: 'algo-web-socket',
        loadChildren: () => import('./algo-web-socket/algo-web-socket.module').then(m => m.AlgoWebSocketModule)
      },
      {
        path: 'alice-web-auto-login/:algoAdminId',
        component: AliceAntAutoLoginComponent 
      }

    ]
  }
];
