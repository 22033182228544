import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {RepositoryService} from './repository-services/repository.service';
import {environment} from '../../environments/environment'; 

@Injectable({
  providedIn: 'root'
})
export class AlgoUserProfileService {

  private apiURL = environment.baseAlgoApiUrl;  
  
  constructor(private repositoryService :  RepositoryService) { }

  public AlgoUserLogin(userName:string, password:string) : boolean
  {
    var result = false;
    if(userName == 'mani' && password == 'algo'){
      result = true;
      sessionStorage.setItem("LoginStatus", "true");    
    }
    else {
      result = false;
      sessionStorage.setItem("LoginStatus", "true");        
    }

    return result;
  }

  public AlgoSiteUserLoginCheck(algoAdminId:string, password : string) : Observable<any>{
    return this.repositoryService.Get(this.apiURL + `/AlgoLogin/AlgoTradeSiteLogin?algoAdminId=${algoAdminId}&password=${password}`);
  }

  public AlgoSiteRegister(algoUserName:string, algoPassword : string, algoEmail:string, isSubBroker : boolean) : Observable<any>
  {
    return this.repositoryService.Post(this.apiURL + `/AlgoLogin/AlgoTradeSiteRegister?username=${algoUserName}&password=${algoPassword}&email=${algoEmail}&isSubBroker=${isSubBroker}`);
  } 

  public GetUserDetailsFromSession(){
    var algoAdminId = 0;
    // if(window.sessionStorage.getItem("ALGO_SITE_KEY")!=null){
    //   algoAdminId =Number(window.sessionStorage.getItem("ALGO_SITE_KEY"));      
    // }
    // else 
    //   console.log("login error"); 

    if(window.localStorage.getItem("ALGO_SITE_KEY")!=null){
      algoAdminId =Number(window.localStorage.getItem("ALGO_SITE_KEY"));      
    }
    else 
      console.log("login error"); 

      return algoAdminId;
  }

  public algoTradeSiteUpdatePassword(algoAdminId:number, algoOldPassword : string, algoNewPassword:string) : Observable<any>
  {
    return this.repositoryService.Post(this.apiURL + `/AlgoLogin/AlgoTradeSiteUpdatePassword?algoAdminId=${algoAdminId}&oldPassword=${algoOldPassword}&newPassword=${algoNewPassword}`);
  } 

}
