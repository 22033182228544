import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent implements OnInit { 
  constructor(private router: Router) { }

  ngOnInit(): void {    

  }
  
  signoutAlgo(){        
    console.log("signoutAlgo start");
    window.localStorage.removeItem("ALGO_SITE_KEY");      
      console.log("signoutAlgo success");
      this.router.navigate(['/login']);
  }
}
